"use client";

import { DehydratedState } from "@tanstack/react-query";
import { SessionProvider } from "next-auth/react";
import { Session } from "next-auth";
import QueryClientProviderWrapper from "@/src/components/QueryClientProviderWrapper";
import ToastProvider from "@/src/components/Toast/ToastProvider";
import { AnalyticsContextProvider } from "@/libs/analytics/AnalyticsProvider";
import OptimizelyProviderWrapper from "@/src/services/optimizely/OptimizelyProviderWrapper";
import { AppGeoLocProvider } from "@/libs/geo/AppGeoLocProvider";
import { AkamaiGeoCookie } from "nvs-constants";
import { fetchOptimizelyClientData } from "../lib/services/optimizely/client/fetchOptimizelyConfig";

type Props = {
  children: React.ReactNode;
  dehydratedState?: DehydratedState;
  session?: Session | null;
  geoLoc?: AkamaiGeoCookie;
};

const RootProviders: React.FC<Props> = ({
  dehydratedState,
  session,
  children,
  geoLoc,
}) => {
  const dataFromOptimizely = fetchOptimizelyClientData();
  return (
    <AppGeoLocProvider geoLoc={geoLoc}>
      <SessionProvider
        session={session}
        // Re-fetches session when window is focused - this is the default
        // https://next-auth.js.org/getting-started/client#refetch-on-window-focus
        refetchOnWindowFocus={true}
      >
        <OptimizelyProviderWrapper dataFromOptimizely={dataFromOptimizely}>
          <QueryClientProviderWrapper dehydratedState={dehydratedState}>
            <ToastProvider>
              <AnalyticsContextProvider country={geoLoc?.country}>
                {/* @TODO add back route progress */}
                {children}
              </AnalyticsContextProvider>
            </ToastProvider>
          </QueryClientProviderWrapper>
        </OptimizelyProviderWrapper>
      </SessionProvider>
    </AppGeoLocProvider>
  );
};

export default RootProviders;
